import { Injectable } from '@angular/core';
import 'rxjs/add/operator/toPromise';

@Injectable()
export class ErrorService {
  constructor() {}

  create(errors: any): any {
    const list_errors: any = [];

    // if there is a list of errors
    if (Array.isArray(errors.error.message)) {
      for (const error of errors.error.message) {
        list_errors[error.property] = error.constraints;
      }
    } else if (errors.error.message) {
      const error_str = errors.error.message.split('.');
      if (list_errors[error_str[0]] == undefined) list_errors[error_str[0]] = [];

      switch (errors.error.message) {
        case 'email.alreadyExist': {
          list_errors[error_str[0]][error_str[1]] = 'email must be unique';
          break;
        }
        case 'matricule.alreadyExist': {
          list_errors[error_str[0]][error_str[1]] = 'matricule must be unique';
          break;
        }
        case 'period.holiday': {
          list_errors[error_str[0]][error_str[1]] = 'activity during holiday';
          break;
        }
        default:
          break;
      }
    }
    return list_errors;
  }

  msg(list_errors: any): string {
    let msg = '';
    const list_keys: any = [];

    for (const property of Object.keys(list_errors)) {
      for (const keys of Object.keys(list_errors[property])) {
        if (!list_keys.includes(keys)) list_keys.push(keys);
      }
    }

    // transform code error to string
    for (const error of list_keys) {
      switch (error) {
        case 'isNotEmpty': {
          msg += 'Des champs sont requis.';
          break;
        }
        case 'alreadyExist': {
          msg += 'Un champ existe déjà.';
          break;
        }
        case 'holiday': {
          msg += "Impossible d'ajouter une activité pendant les vacances.";
          break;
        }
        default:
          break;
      }
    }
    return msg;
  }
}
