import { Action } from '@ngrx/store';
import { UserModel } from '../model/user.model';

export const ADD_USER = '[UserModel] Add';
export const NEW_USER = 'UserModel New';
export const REMOVE_USER = '[UserModel] Remove';
export const CLEAR_USER = '[UserModel] CLear';

export class AddUser implements Action {
  readonly type = ADD_USER;

  constructor(public payload: UserModel) {}
}

export class RemoveUser implements Action {
  readonly type = REMOVE_USER;

  constructor(public payload: number) {}
}

export class ClearUser implements Action {
  readonly type = CLEAR_USER;

  constructor() {}
}

export class NewUser implements Action {
  readonly type = NEW_USER;

  constructor(public payload: UserModel) {}
}

// Section 4
export type Actions = AddUser | RemoveUser | ClearUser | NewUser;
