import { Component, OnDestroy, OnInit } from '@angular/core';
import { NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService } from '@nebular/theme';

import { LayoutService } from '../../../@core/utils';
import { map, takeUntil } from 'rxjs/operators';
import {Observable, Subject} from 'rxjs';
import {UserModel} from '../../../_store/model/user.model';
import {ContextModel} from '../../../_store/model/context.model';
import {Store} from '@ngrx/store';
import {UserState} from '../../../_store/state/principal.state';
import {NgxPermissionsService} from 'ngx-permissions';
import {ContextState} from '../../../_store/state/context.state';
import {Router} from '@angular/router';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {

  userPictureOnly: boolean = false;
  user: any;

  themes = [
    {
      value: 'default',
      name: 'Light',
    }
  ];

  currentTheme = 'default';

  store: Observable<UserModel>;
  context: Observable<ContextModel>;
  private destroy$: Subject<void> = new Subject<void>();

  userMenu = [ { title: 'Mon profil', url: '/pages/profile' }, { title: 'Se déconnecter', url: '/auth/logout' } ];

  constructor(private sidebarService: NbSidebarService,
              private menuService: NbMenuService,
              private themeService: NbThemeService,
              private layoutService: LayoutService,
              private storeUser: Store<UserState>,
              private permissionService: NgxPermissionsService,
              private storeContext: Store<ContextState>,
              private breakpointService: NbMediaBreakpointsService,
              protected router: Router) {
    this.store = storeUser.select('user');
    this.context = storeContext.select('context');
  }

  ngOnInit() {
    this.initUser();

    this.currentTheme = this.themeService.currentTheme;

    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService.onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
      )
      .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

    this.themeService.onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$),
      )
      .subscribe(themeName => this.currentTheme = themeName);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  initUser() {
    this.store.pipe(takeUntil(this.destroy$)).subscribe(
      resp => {
        this.user = resp.user;
      });
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  goTo(url) {
    this.router.navigateByUrl(url);
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }
}
