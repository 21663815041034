/**
 * VObot API
 *  A RESTful API for VObot app
 *
 * OpenAPI spec version: 1.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { CriterionView } from '../model/criterionView';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class CriterionService {

    protected basePath = '';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Import list of criterion from xslx file
     *
     * @param categoryId
     * @param file
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createCriterionActionImportForm(categoryId: string, file: Blob, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public createCriterionActionImportForm(categoryId: string, file: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public createCriterionActionImportForm(categoryId: string, file: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public createCriterionActionImportForm(categoryId: string, file: Blob, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (categoryId === null || categoryId === undefined) {
            throw new Error('Required parameter categoryId was null or undefined when calling createCriterionActionImport.');
        }

        if (file === null || file === undefined) {
            throw new Error('Required parameter file was null or undefined when calling createCriterionActionImport.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (categoryId !== undefined) {
            formParams = formParams.append('categoryId', <any>categoryId) as any || formParams;
        }
        if (file !== undefined) {
            formParams = formParams.append('file', <any>file) as any || formParams;
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/v1/criterions/import/`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create new criterion
     *
     * @param title
     * @param description
     * @param max_value
     * @param checkbox
     * @param type
     * @param file
     * @param categories
     * @param active
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createCriterionActionIndexForm(title: string, description: string, max_value: number, checkbox: boolean, type: string, file: Blob, categories: Array<string>, active: boolean, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public createCriterionActionIndexForm(title: string, description: string, max_value: number, checkbox: boolean, type: string, file: Blob, categories: Array<string>, active: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public createCriterionActionIndexForm(title: string, description: string, max_value: number, checkbox: boolean, type: string, file: Blob, categories: Array<string>, active: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public createCriterionActionIndexForm(title: string, description: string, max_value: number, checkbox: boolean, type: string, file: Blob, categories: Array<string>, active: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (title === null || title === undefined) {
            throw new Error('Required parameter title was null or undefined when calling createCriterionActionIndex.');
        }

        if (description === null || description === undefined) {
            throw new Error('Required parameter description was null or undefined when calling createCriterionActionIndex.');
        }

        if (max_value === null || max_value === undefined) {
            throw new Error('Required parameter max_value was null or undefined when calling createCriterionActionIndex.');
        }

        if (checkbox === null || checkbox === undefined) {
            throw new Error('Required parameter checkbox was null or undefined when calling createCriterionActionIndex.');
        }

        if (type === null || type === undefined) {
            throw new Error('Required parameter type was null or undefined when calling createCriterionActionIndex.');
        }

        if (categories === null || categories === undefined) {
            throw new Error('Required parameter categories was null or undefined when calling createCriterionActionIndex.');
        }

        if (active === null || active === undefined) {
            throw new Error('Required parameter active was null or undefined when calling createCriterionActionIndex.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (title !== undefined) {
            formParams = formParams.append('title', <any>title) as any || formParams;
        }
        if (description !== undefined) {
            formParams = formParams.append('description', <any>description) as any || formParams;
        }
        if (max_value !== undefined) {
            formParams = formParams.append('max_value', <any>max_value) as any || formParams;
        }
        if (checkbox !== undefined) {
            formParams = formParams.append('checkbox', <any>checkbox) as any || formParams;
        }
        if (type !== undefined) {
            formParams = formParams.append('type', <any>type) as any || formParams;
        }
        if (file !== undefined) {
            formParams = formParams.append('file', <any>file) as any || formParams;
        }
        if (categories) {
            categories.forEach((element) => {
                formParams = formParams.append('categories', <any>element) as any || formParams;
            })
        }
        if (active !== undefined) {
            formParams = formParams.append('active', <any>active) as any || formParams;
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/v1/criterions`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete criterion
     *
     * @param id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteCriterionActionIndex(id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteCriterionActionIndex(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteCriterionActionIndex(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteCriterionActionIndex(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling deleteCriterionActionIndex.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('delete',`${this.basePath}/api/v1/criterions/${encodeURIComponent(String(id))}/`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get criterion
     *
     * @param id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCriterionActionIndex(id: string, observe?: 'body', reportProgress?: boolean): Observable<CriterionView>;
    public getCriterionActionIndex(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CriterionView>>;
    public getCriterionActionIndex(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CriterionView>>;
    public getCriterionActionIndex(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getCriterionActionIndex.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<CriterionView>('get',`${this.basePath}/api/v1/criterions/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all criterions
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCriterionActionList(observe?: 'body', reportProgress?: boolean): Observable<Array<CriterionView>>;
    public getCriterionActionList(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<CriterionView>>>;
    public getCriterionActionList(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<CriterionView>>>;
    public getCriterionActionList(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<CriterionView>>('get',`${this.basePath}/api/v1/criterions`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update criterion
     *
     * @param title
     * @param description
     * @param max_value
     * @param checkbox
     * @param type
     * @param file
     * @param categories
     * @param active
     * @param id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateCriterionActionIndexForm(title: string, description: string, max_value: number, checkbox: boolean, type: string, file: Blob, categories: Array<string>, active: boolean, id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public updateCriterionActionIndexForm(title: string, description: string, max_value: number, checkbox: boolean, type: string, file: Blob, categories: Array<string>, active: boolean, id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public updateCriterionActionIndexForm(title: string, description: string, max_value: number, checkbox: boolean, type: string, file: Blob, categories: Array<string>, active: boolean, id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public updateCriterionActionIndexForm(title: string, description: string, max_value: number, checkbox: boolean, type: string, file: Blob, categories: Array<string>, active: boolean, id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (title === null || title === undefined) {
            throw new Error('Required parameter title was null or undefined when calling updateCriterionActionIndex.');
        }

        if (description === null || description === undefined) {
            throw new Error('Required parameter description was null or undefined when calling updateCriterionActionIndex.');
        }

        if (max_value === null || max_value === undefined) {
            throw new Error('Required parameter max_value was null or undefined when calling updateCriterionActionIndex.');
        }

        if (checkbox === null || checkbox === undefined) {
            throw new Error('Required parameter checkbox was null or undefined when calling updateCriterionActionIndex.');
        }

        if (type === null || type === undefined) {
            throw new Error('Required parameter type was null or undefined when calling updateCriterionActionIndex.');
        }

        if (categories === null || categories === undefined) {
            throw new Error('Required parameter categories was null or undefined when calling updateCriterionActionIndex.');
        }

        if (active === null || active === undefined) {
            throw new Error('Required parameter active was null or undefined when calling updateCriterionActionIndex.');
        }

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling updateCriterionActionIndex.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (title !== undefined) {
            formParams = formParams.append('title', <any>title) as any || formParams;
        }
        if (description !== undefined) {
            formParams = formParams.append('description', <any>description) as any || formParams;
        }
        if (max_value !== undefined) {
            formParams = formParams.append('max_value', <any>max_value) as any || formParams;
        }
        if (checkbox !== undefined) {
            formParams = formParams.append('checkbox', <any>checkbox) as any || formParams;
        }
        if (type !== undefined) {
            formParams = formParams.append('type', <any>type) as any || formParams;
        }
        if (file !== undefined) {
            formParams = formParams.append('file', <any>file) as any || formParams;
        }
        if (categories) {
            categories.forEach((element) => {
                formParams = formParams.append('categories', <any>element) as any || formParams;
            })
        }
        if (active !== undefined) {
            formParams = formParams.append('active', <any>active) as any || formParams;
        }

        return this.httpClient.request<any>('put',`${this.basePath}/api/v1/criterions/${encodeURIComponent(String(id))}`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
