/**
 * VObot API
 *  A RESTful API for VObot app
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface CredentialDTO { 
    email: string;
    password: string;
}