import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';


import { AllianceService } from './api/alliance.service';
import { AuthService } from './api/auth.service';
import { BookingService } from './api/booking.service';
import { BookingToTeamService } from './api/bookingToTeam.service';
import { CategoryService } from './api/category.service';
import { CategoryToCriterionService } from './api/categoryToCriterion.service';
import { CredentialService } from './api/credential.service';
import { CriterionService } from './api/criterion.service';
import { GradeService } from './api/grade.service';
import { JuryService } from './api/jury.service';
import { ProjectService } from './api/project.service';
import { RightService } from './api/right.service';
import { SchoolService } from './api/school.service';
import { SheetService } from './api/sheet.service';
import { SheetToTeamService } from './api/sheetToTeam.service';
import { TableService } from './api/table.service';
import { TagService } from './api/tag.service';
import { TeamService } from './api/team.service';
import { TemplateService } from './api/template.service';
import { TemplateToCategoryService } from './api/templateToCategory.service';
import { UserService } from './api/user.service';
import { UserToRightService } from './api/userToRight.service';
import { UserToTemplateService } from './api/userToTemplate.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: [
    AllianceService,
    AuthService,
    BookingService,
    BookingToTeamService,
    CategoryService,
    CategoryToCriterionService,
    CredentialService,
    CriterionService,
    GradeService,
    JuryService,
    ProjectService,
    RightService,
    SchoolService,
    SheetService,
    SheetToTeamService,
    TableService,
    TagService,
    TeamService,
    TemplateService,
    TemplateToCategoryService,
    UserService,
    UserToRightService,
    UserToTemplateService ]
})
export class ApiModule {

    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
