import { Action } from '@ngrx/store';
import { ContextModel } from '../model/context.model';

export const ADD_CONTEXT = '[ContextModel] Add';
export const NEW_CONTEXT = 'ContextModel New';
export const REMOVE_CONTEXT = '[ContextModel] Remove';
export const CLEAR_CONTEXT = '[ContextModel] CLear';

export class AddContext implements Action {
  readonly type = ADD_CONTEXT;

  constructor(public payload: ContextModel) {}
}

export class RemoveContext implements Action {
  readonly type = REMOVE_CONTEXT;

  constructor(public payload: number) {}
}

export class ClearContext implements Action {
  readonly type = CLEAR_CONTEXT;

  constructor() {}
}

export class NewContext implements Action {
  readonly type = NEW_CONTEXT;

  constructor(public payload: ContextModel) {}
}

// Section 4
export type Actions = AddContext | RemoveContext | ClearContext | NewContext;
