/**
 * VObot API
 *  A RESTful API for VObot app
 *
 * OpenAPI spec version: 1.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { SheetDTO } from '../model/sheetDTO';
import { SheetView } from '../model/sheetView';
import { TeamResultView } from '../model/teamResultView';
import { TemplateWithSheetsView } from '../model/templateWithSheetsView';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class SheetService {

    protected basePath = '';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Create new sheet
     *
     * @param body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createSheetActionIndex(body: SheetDTO, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public createSheetActionIndex(body: SheetDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public createSheetActionIndex(body: SheetDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public createSheetActionIndex(body: SheetDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling createSheetActionIndex.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/v1/sheets`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete sheet
     *
     * @param id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteSheetActionIndex(id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteSheetActionIndex(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteSheetActionIndex(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteSheetActionIndex(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling deleteSheetActionIndex.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('delete',`${this.basePath}/api/v1/sheets/${encodeURIComponent(String(id))}/`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all full sheets for one competition
     *
     * @param id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSheetActionFullList(id: string, observe?: 'body', reportProgress?: boolean): Observable<Array<TeamResultView>>;
    public getSheetActionFullList(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<TeamResultView>>>;
    public getSheetActionFullList(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<TeamResultView>>>;
    public getSheetActionFullList(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getSheetActionFullList.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<TeamResultView>>('get',`${this.basePath}/api/v1/sheets/full/template/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all full sheets for one competition for a specific group
     *
     * @param id
     * @param group
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSheetActionFullListByGroup(id: string, group: string, observe?: 'body', reportProgress?: boolean): Observable<Array<TeamResultView>>;
    public getSheetActionFullListByGroup(id: string, group: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<TeamResultView>>>;
    public getSheetActionFullListByGroup(id: string, group: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<TeamResultView>>>;
    public getSheetActionFullListByGroup(id: string, group: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getSheetActionFullListByGroup.');
        }

        if (group === null || group === undefined) {
            throw new Error('Required parameter group was null or undefined when calling getSheetActionFullListByGroup.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<TeamResultView>>('get',`${this.basePath}/api/v1/sheets/full/templategroup/${encodeURIComponent(String(id))}/${encodeURIComponent(String(group))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all full sheets for one competition for a specific group order by city
     *
     * @param id
     * @param group
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSheetActionFullListByGroupCityOrder(id: string, group: string, observe?: 'body', reportProgress?: boolean): Observable<Array<TeamResultView>>;
    public getSheetActionFullListByGroupCityOrder(id: string, group: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<TeamResultView>>>;
    public getSheetActionFullListByGroupCityOrder(id: string, group: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<TeamResultView>>>;
    public getSheetActionFullListByGroupCityOrder(id: string, group: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getSheetActionFullListByGroupCityOrder.');
        }

        if (group === null || group === undefined) {
            throw new Error('Required parameter group was null or undefined when calling getSheetActionFullListByGroupCityOrder.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<TeamResultView>>('get',`${this.basePath}/api/v1/sheets/full/templategroupordercity/${encodeURIComponent(String(id))}/${encodeURIComponent(String(group))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get sheet
     *
     * @param id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSheetActionIndex(id: string, observe?: 'body', reportProgress?: boolean): Observable<SheetView>;
    public getSheetActionIndex(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SheetView>>;
    public getSheetActionIndex(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SheetView>>;
    public getSheetActionIndex(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getSheetActionIndex.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<SheetView>('get',`${this.basePath}/api/v1/sheets/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all sheets
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSheetActionList(observe?: 'body', reportProgress?: boolean): Observable<Array<SheetView>>;
    public getSheetActionList(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<SheetView>>>;
    public getSheetActionList(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<SheetView>>>;
    public getSheetActionList(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<SheetView>>('get',`${this.basePath}/api/v1/sheets`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all sheets by template and team id
     *
     * @param templateId
     * @param teamId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSheetActionTemplateByTeamList(templateId: string, teamId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<TemplateWithSheetsView>>;
    public getSheetActionTemplateByTeamList(templateId: string, teamId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<TemplateWithSheetsView>>>;
    public getSheetActionTemplateByTeamList(templateId: string, teamId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<TemplateWithSheetsView>>>;
    public getSheetActionTemplateByTeamList(templateId: string, teamId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (templateId === null || templateId === undefined) {
            throw new Error('Required parameter templateId was null or undefined when calling getSheetActionTemplateByTeamList.');
        }

        if (teamId === null || teamId === undefined) {
            throw new Error('Required parameter teamId was null or undefined when calling getSheetActionTemplateByTeamList.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<TemplateWithSheetsView>>('get',`${this.basePath}/api/v1/sheets/template/${encodeURIComponent(String(templateId))}/team/${encodeURIComponent(String(teamId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all sheets by template and user id
     *
     * @param templateId
     * @param userId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSheetActionTemplateList(templateId: string, userId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<TemplateWithSheetsView>>;
    public getSheetActionTemplateList(templateId: string, userId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<TemplateWithSheetsView>>>;
    public getSheetActionTemplateList(templateId: string, userId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<TemplateWithSheetsView>>>;
    public getSheetActionTemplateList(templateId: string, userId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (templateId === null || templateId === undefined) {
            throw new Error('Required parameter templateId was null or undefined when calling getSheetActionTemplateList.');
        }

        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling getSheetActionTemplateList.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<TemplateWithSheetsView>>('get',`${this.basePath}/api/v1/sheets/template/${encodeURIComponent(String(templateId))}/user/${encodeURIComponent(String(userId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all sheets by user id
     *
     * @param id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSheetActionUserList(id: string, observe?: 'body', reportProgress?: boolean): Observable<Array<SheetView>>;
    public getSheetActionUserList(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<SheetView>>>;
    public getSheetActionUserList(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<SheetView>>>;
    public getSheetActionUserList(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getSheetActionUserList.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<SheetView>>('get',`${this.basePath}/api/v1/sheets/user/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update sheet
     *
     * @param body
     * @param id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateSheetActionIndex(body: SheetDTO, id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public updateSheetActionIndex(body: SheetDTO, id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public updateSheetActionIndex(body: SheetDTO, id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public updateSheetActionIndex(body: SheetDTO, id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling updateSheetActionIndex.');
        }

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling updateSheetActionIndex.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('put',`${this.basePath}/api/v1/sheets/${encodeURIComponent(String(id))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
