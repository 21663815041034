export * from './alliance.service';
import { AllianceService } from './alliance.service';
export * from './auth.service';
import { AuthService } from './auth.service';
export * from './booking.service';
import { BookingService } from './booking.service';
export * from './bookingToTeam.service';
import { BookingToTeamService } from './bookingToTeam.service';
export * from './category.service';
import { CategoryService } from './category.service';
export * from './categoryToCriterion.service';
import { CategoryToCriterionService } from './categoryToCriterion.service';
export * from './credential.service';
import { CredentialService } from './credential.service';
export * from './criterion.service';
import { CriterionService } from './criterion.service';
export * from './grade.service';
import { GradeService } from './grade.service';
export * from './jury.service';
import { JuryService } from './jury.service';
export * from './project.service';
import { ProjectService } from './project.service';
export * from './right.service';
import { RightService } from './right.service';
export * from './school.service';
import { SchoolService } from './school.service';
export * from './sheet.service';
import { SheetService } from './sheet.service';
export * from './sheetToTeam.service';
import { SheetToTeamService } from './sheetToTeam.service';
export * from './table.service';
import { TableService } from './table.service';
export * from './tag.service';
import { TagService } from './tag.service';
export * from './team.service';
import { TeamService } from './team.service';
export * from './template.service';
import { TemplateService } from './template.service';
export * from './templateToCategory.service';
import { TemplateToCategoryService } from './templateToCategory.service';
export * from './user.service';
import { UserService } from './user.service';
export * from './userToRight.service';
import { UserToRightService } from './userToRight.service';
export * from './userToTemplate.service';
import { UserToTemplateService } from './userToTemplate.service';
export const APIS = [AllianceService, AuthService, BookingService, BookingToTeamService, CategoryService, CategoryToCriterionService, CredentialService, CriterionService, GradeService, JuryService, ProjectService, RightService, SchoolService, SheetService, SheetToTeamService, TableService, TagService, TeamService, TemplateService, TemplateToCategoryService, UserService, UserToRightService, UserToTemplateService];
