import { ContextModel } from '../model/context.model';
import { ADD_CONTEXT, CLEAR_CONTEXT, NEW_CONTEXT, REMOVE_CONTEXT } from '../actions/context.actions';

const initialState: ContextModel = {
  context: '',
};

export function contextReducer(state: ContextModel = initialState, action) {
  switch (action.type) {
    case ADD_CONTEXT:
      return action.payload;
    case NEW_CONTEXT:
      return action.payload;
    case REMOVE_CONTEXT:
      state = { context: '' };
      return state;
    case CLEAR_CONTEXT:
      state = initialState;
      return state;
    default:
      return state;
  }
}
