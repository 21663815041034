<div class="header-container">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a>
    <a class="logo" href="#" (click)="navigateHome()"
      ><img class="logo_vobot" src="assets/images/logo_vobot.jpg"></a
    >
  </div>
</div>

<div class="header-container">
  <img class="logo_vo" src="assets/images/logo_vo.png">
  <nb-actions size="small">
    <!--<nb-action class="control-item">
      <nb-search type="rotate-layout"></nb-search>
    </nb-action>-->

    <nb-action class="user-action" *nbIsGranted="['view', 'user']">
      <nb-user
        [nbContextMenu]="userMenu"
        [name]="user?.firstname + ' ' + user?.lastname"
        [picture]="'assets/images/user.png'"
      >
      </nb-user>
    </nb-action>
  </nb-actions>
</div>
