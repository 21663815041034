import { UserModel } from '../model/user.model';
import { ADD_USER, CLEAR_USER, NEW_USER, REMOVE_USER } from '../actions/user.actions';
import { UserLogin } from '../../_models/userLogin';

const initialState: UserModel = {
  user: new UserLogin(),
};

export function userReducer(state: UserModel = initialState, action) {
  switch (action.type) {
    case ADD_USER:
      return action.payload;
    case NEW_USER:
      return action.payload;
    case REMOVE_USER:
      state = { user: new UserLogin() };
      return state;
    case CLEAR_USER:
      state = initialState;
      return state;
    default:
      return state;
  }
}
