export interface UserLoginInstance {
  id: string;
  apiToken: string;
  firstname: string;
  lastname: string;
  email: string;
  rights: string[];
}

export class UserLogin implements UserLoginInstance {
  id: string = null;
  firstname = '';
  lastname = '';
  apiToken = '';
  email = '';
  rights: string[] = [];
  constructor() {}

  copy(user: any) {
    this.id = user.id;
    this.firstname = user.firstname;
    this.lastname = user.lastname;
    this.email = user.email;
    this.apiToken = user?.apiToken;
    this.rights = user?.rights;
  }
}
